import React, { useContext, useState, useEffect } from "react";
import { navigate } from "gatsby";

import { Router } from "@reach/router";

import { PlanProvider, PlanContext } from "../../providers/plan";

import PaymentImage from "../../images/payment-gif.gif";

import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Loading from "../../components/loading";

const Progress = () => {
    return (
        <Layout>
            <PlanProvider>
                <Router>
                    <PurchaseInProgressDummyPage path="purchase/progress" />
                    <PurchaseInProgressPage path="purchase/progress/:orderId" />
                </Router>
            </PlanProvider>
        </Layout>
    );
};

export default Progress;

const PurchaseInProgressDummyPage = () => {
    useEffect(() => {
        navigate("/purchase/currentSubscription");
    });

    return <Loading />;
};

const PurchaseInProgressPage = ({ orderId }) => {
    const planContext = useContext(PlanContext);

    const [order, setOrder] = useState(null);
    const [view, setView] = useState(<Loading />);

    useEffect(() => {
        const getOrderRecursion = () => {
            planContext
                .getOrder(orderId)
                .then((data) => {
                    const o = data.data;
                    if (o.status === "completed") {
                        var returnURL = "/purchase/currentSubscription";

                        if (o.job_type === "REAL_SIM") {
                            returnURL = "/job/realsim";
                        }

                        navigate(returnURL);
                    }
                    setOrder(o);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        getOrderRecursion();

        const fifteenSecond = 15 * 1000;

        const timerId = setInterval(() => {
            getOrderRecursion();
        }, fifteenSecond);

        return () => clearInterval(timerId);
    }, [planContext, orderId]);

    useEffect(() => {
        if (order) {
            var detailHtml = <></>;
            if (order.status === "cancelled") {
                detailHtml = (
                    <>
                        <h1 className="my-8">Purchase was cancelled</h1>
                        <p className="my-1">
                            Your order ID is {order.order_id}.
                        </p>
                        <p
                            className={
                                "my-1 " +
                                (order.job_type === "REAL_SIM" ? "" : "hidden")
                            }
                        >
                            Your job name is {order.job_name}.
                        </p>
                        <p className="my-1">
                            If you have any question, please contact{" "}
                            <a href="mailto:enquiry@cpas.earth">
                                enquiry@cpas.earth
                            </a>
                            .
                        </p>
                    </>
                );
            } else {
                detailHtml = (
                    <>
                        <h1 className="my-8">Purchase in progress</h1>
                        <p className="my-1">We are processing your order.</p>
                        <p className="my-1">
                            Your order ID is {order.order_id}.
                        </p>
                        <p
                            className={
                                "my-1 " +
                                (order.job_type === "REAL_SIM" ? "" : "hidden")
                            }
                        >
                            Your job name is {order.job_name}.
                        </p>
                        <p className="my-1">
                            If the order is not completed within 5 minutes,
                            please contact{" "}
                            <a href="mailto:enquiry@cpas.earth">
                                enquiry@cpas.earth
                            </a>
                            .
                        </p>
                    </>
                );
            }
            setView(
                <>
                    <div className="flex flex-row items-stretch bg-grey-light h-12">
                        <div className="flex flex-row items-center flex-1 ml-4">
                            Purchase in progress
                        </div>
                    </div>

                    <div className="container m-auto">
                        <div className="flex flex-row mt-32">
                            <div className="w-1/3">
                                <img
                                    className="w-2/3"
                                    src={PaymentImage}
                                    alt="Payment in progress"
                                ></img>
                            </div>
                            <div className="w-2/3 flex flex-col">
                                {detailHtml}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }, [order]);

    return (
        <>
            <Seo title="Purchase in porgress" />
            {view}
        </>
    );
};
